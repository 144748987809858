<template>
  <div>
      <div class="my-6 text-start">
      <h3 class="font-bold">Criar um novo usuário</h3>
      <span class="text-sm text-gray-500"
        >Preencha as informações abaixo e clique no botão <b>criar</b> para salvar
        as alterações.
      </span>
    </div>

    <div class="card shadow-sm bg-white border p-8">
      <div class="flex lg:flex-row flex-col mt-5">
        <div class="w-full p-2">
          <Input
            v-model="user.usuario"
            type="default"
            label="Usuario"
            placeholder="Usuario"
          />
        </div>
        <div class="w-full p-2 relative">
          <Input
            v-model="user.senha"
            :type="type"
            placeholder="Senha"
            label="Senha"
          />
          <i :class="`gg-${icon} icon`" @click="changeInputType"></i>
        </div>
      </div>
      <div class="flex justify-end mt-5">
      <Button @click="create" color="primary" text="Criar"></Button>
      </div>
    </div>
  </div>
</template>

<script>
import Input from "../../components/Elements/Input.vue";
import Button from "../../components/Elements/Button.vue";
import { inject, ref } from "vue";
import { POST } from "../../services/api";
import { isEmpty, isEmail } from "../../services/validate";
import { useRouter } from 'vue-router';

export default {
  name: "CriarUsuario",
  components: {
    Input,
    Button,
  }, 
  data()
  {
    return{
      isMobile: false
    }
  },

  created() {
    this.isMobile = window.innerWidth < 800 ? true : false;
    window.addEventListener("resize", () => {
      this.isMobile = window.innerWidth < 800 ? true : false;
    });
  },
  setup() {
    const user = ref({
      usuario: "",
      senha: ""
    });
    const icon = ref("eye");
    const type = ref("password");
    const loader = inject("loading");
    const alert = inject("alert");
    const router = useRouter();

    const create = async () => {
      loader.open();
      const validate = isEmpty(user.value);
      const emailValidate = isEmail(user.value.email);
      if (validate && !emailValidate) {
        if (!emailValidate) alert.open("Atenção!", `O email <b>${user.value.email}</b> não é válido!`, "warning");
        if (validate) alert.open("Atenção!", validate, "warning"); 
      } else {
        try {
          await POST("usuarios", user.value);
          alert.open(
            "Sucesso!",
            `Usuário criado com sucesso!`,
            "success"
          );
          loader.close();
          router.push("/usuarios");
        } catch (e) {
          alert.open("Atenção!", e, "danger");
        }
      }
      loader.close();
    };

    const changeInputType = () => {
      type.value = type.value == 'password' ? 'default' : 'password';
      icon.value = icon.value == 'eye' ? 'eye-alt' : 'eye';
    };

    return {
      user,
      create,
      icon,
      type,
      changeInputType
    };
  },
};
</script>

<style scoped>
  .icon{
    cursor: pointer;
    position: absolute;
    right: 20px;
    top: 38%;
  }

  .block{
    display: block;
  }

  .btn{
    width: 100%;
    align-items: center;
    justify-content: center;
  }

  .mobile{
  height: 100vh;
  position:absolute;
  margin-right: 25px;
  }
</style>
